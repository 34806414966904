<template>
  <div>
    {{ $t("components.carverInscriptions.carver") }}
    <b-list-group>
      <b-list-group-item v-for="(item, index) in content" :key="index">
        <b-row>
          <b-col>
            <multiselect
              v-if="carvers.length > 0"
              v-model="item.carver.id"
              :options="carvers.map(i => i.id)"
              :custom-label="key => carvers.find(x => x.id == key).carver"
              :show-labels="false"
              @change="changed"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="auto">
            {{ $t("components.carverInscriptions.certainty") }}
          </b-col>
          <b-col sm="auto">
            <b-form-checkbox
              v-model="item.certainty"
              name="carverInscriptions-checkbox"
              switch
              @change="changed"
            >
              {{
                item.certainty
                  ? $t("components.carverInscriptions.certain")
                  : $t("components.carverInscriptions.uncertain")
              }}
            </b-form-checkbox>
          </b-col>
          <b-col>
            <multiselect
              v-model="item.attribution"
              :options="attributions.map(i => i.value)"
              :custom-label="key => attributions.find(x => x.value == key).text"
              :show-labels="false"
              @change="changed"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <label :for="'carverInscriptions-' + index">{{
              $t("components.carverInscriptions.notes")
            }}</label>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-input
              :id="'carverInscriptions-' + index"
              v-model="item.notes"
              @change="changed"
              @input="changed"
            />
          </b-col>
          <b-col sm="auto">
            <multiselect
              v-if="languages.length > 0"
              v-model="item.lang.id"
              :options="languages.map(i => i.id)"
              :custom-label="key => languages.find(x => x.id == key).sv"
              :show-labels="false"
              @select="changed"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="auto">
            <b-button
              v-t="'components.carverInscriptions.delete'"
              @click="deleteCarver(index)"
            />
          </b-col>
        </b-row>
        <sources-selector
          v-if="item['sources']"
          :id="'style-' + index + '-sources'"
          v-model="item['sources']"
          @change="changed"
        />
      </b-list-group-item>
    </b-list-group>
    <b-button v-t="'components.carverInscriptions.new'" @click="addCarver" />
  </div>
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "CarverInscriptions",
  components: {
    SourcesSelector: () => import("@/components/SourcesSelector")
  },
  props: {
    value: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      content: this.value,
      carvers: [],
      languages: [],
      attributions: [
        {
          value: "attributed",
          text: this.$t("components.carverInscriptions.attributed")
        },
        {
          value: "signed",
          text: this.$t("components.carverInscriptions.signed")
        },
        {
          value: "similar",
          text: this.$t("components.carverInscriptions.similar")
        },
        {
          value: "signed on pair stone",
          text: this.$t("components.carverInscriptions.signed_on_pair_stone")
        }
      ]
    };
  },
  watch: {
    value() {
      this.content = this.value;
    }
  },
  methods: {
    handleInput(e) {
      this.$emit("input", this.content);
    },
    changed() {
      this.$emit("change");
    },
    addCarver() {
      this.content.push({
        attribution: "attributed",
        carver: { id: null },
        certainty: true,
        notes: null,
        lang: { id: "sv-se" },
        sources: []
      });
      this.$emit("change");
    },
    deleteCarver(index) {
      this.content.splice(index, 1);
      this.$emit("change");
    }
  },
  apollo: {
    carvers() {
      return {
        query: gql`
          query carvers {
            carvers {
              id
              carver
            }
            languages: modernLanguages {
              id: language
              sv
            }
          }
        `,
        result(result) {
          if (result.stale) {
            // result is called again with stale = true
            return;
          }

          this.carvers = result.data.carvers.sort((a, b) =>
            a.carver.localeCompare(b.carver, "sv")
          );

          this.languages = result.data.languages.sort((a, b) =>
            a.sv.localeCompare(b.sv, "sv")
          );
        }
      };
    }
  }
};
</script>
